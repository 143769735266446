import { Stack, Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import { web3 } from "../contracts/ContractProvider";
import { rarities } from "../rarities";

const useStyles = makeStyles({
  root: {
    zIndex: 9,
    borderRadius: "10px",
    transition: "background 0s ease-in-out",
    "&:hover": {
      background:
        "url(https://test.app.shiryoinu.com/viewbtn.svg) no-repeat center center / auto, linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25))",
    },
  },
});

export default function GridItemCard({ item, index, isLoaded, market }) {
  const classes = useStyles();

  let id = 0;

  id = market ? item.card.tokenId : item.number;

  let price = 0;

  price =
    market &&
    item.card.price &&
    web3.utils.fromWei(item.card.price.toString(), "ether");

  return (
    <Box className={classes.root}>
      <NavLink
        to={market ? `/market/${item.card.itemId}` : `/card/${item.number}`}
      >
        <Box
          sx={{
            position: "relative",
            border: "1px solid rgba(56,59,77,0.3)",
            textAlign: "center",
            width: "170px",
            height: "237px",
            borderRadius: "10px",
            background:
              "linear-gradient(360deg, #060616 17.52%, rgba(0, 0, 0, 0) 52.17%), url(https://s3.eu-west-1.amazonaws.com/cards.shiryo.com/" +
              id +
              ".png)",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: "-9",
          }}
        >
          <Stack
            sx={{
              p: 1,
              position: "absolute",
              bottom: "4px",
              left: "4px",
              right: "4px",
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                sx={{ fontWeight: "bold", fontSize: "10px", color: "#fff" }}
              >
                {item.card_name.length > 10
                  ? item.card_name.substring(10, 0).toUpperCase() + "..."
                  : item.card_name.toUpperCase()}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: rarities[item.rarity.toLowerCase()],
                  fontWeight: "bold",
                }}
              >
                {item.rarity.toUpperCase()}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {market ? (
                <Typography sx={{ fontSize: "10px", color: "#93A3AF" }}>
                  {price} MATIC
                </Typography>
              ) : (
                <Typography sx={{ fontSize: "10px", color: "#93A3AF" }}>
                  QTY: {item.quantity}
                </Typography>
              )}
              <Typography sx={{ fontSize: "10px", color: "#93A3AF" }}>
                {item.type.toUpperCase()}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </NavLink>
    </Box>
  );
}
