import { useState, useEffect } from "react";
import { Suspense, useLayoutEffect } from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF, Stage, OrbitControls } from "@react-three/drei";

function Model({ model, ...props }) {
  const { scene, nodes, materials } = useGLTF(model);

  useLayoutEffect(() => {
    scene.rotation.x = 300;
    scene.rotation.z = 90;
    scene.traverse((obj) => {
      if (obj.isMesh) {
        obj.position.z = -2;
      }
    });
  }, [scene, nodes, materials]);

  return <primitive object={scene} {...props} />;
}

export default function Pack(props) {
  const [model, setModel] = useState("normal_new.glb");
  const [width, setWidth] = useState(window.innerWidth);
  const [packSize, setPackSize] = useState(310);

  useEffect(() => {
    setModel(props.model);
    if (width < 768) {
      setPackSize(180);
    } else if (width < 1366) {
      setPackSize(240);
    } else {
      setPackSize(310);
    }
  }, []);
  return (
    <Canvas dpr={[1, 2]} shadows camera={{ fov: 45 }} style={{ margin: "0 auto", height: packSize, width: packSize }}>
      <color attach="background" args={["#111120"]} />
      <Suspense fallback={null}>
        <Stage>
          <Model model={model} />
          <OrbitControls target={[0, 7, 0]} autoRotate enableZoom={false} enablePan={false} />
        </Stage>
      </Suspense>
    </Canvas>
  );
}
