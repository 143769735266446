import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import CardGrid from "../Components/CardGrid";
import GridItemCard from "../Components/GridItemCard";

export default function OpenPack({ open, setOpen, cards }) {
  const [items, setItems] = useState(cards);
  const [loading, setLoading] = useState(false);

  useEffect(() => { }, []);

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          py: 3,
          px: 5,
          background: "url('../top.png') no-repeat center center / cover",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          fontSize: "1.33rem",
          textAlign: "center",
        }}
      >
        REVEALING YOUR CARDS
      </DialogTitle>

      {loading ? (
        <DialogContent>
          <Grid
            container
            spacing={1}
            sx={{ pt: 3 }}
            justifyContent="space-around"
          >
            Loading....
          </Grid>
        </DialogContent>
      ) : (
        <DialogContent sx={{ my: 3 }}>
          <Grid
            container
            spacing={2}
            sx={{ pt: 3, pb: 3 }}
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))"
          >

            {items.map((item, index) => (
              <Grid item key={index} sx={{ margin: "0 auto", zIndex: "9" }}>
                <GridItemCard
                  item={item}
                  index={index}
                  isLoaded={true}
                />
              </Grid>
            ))}

          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Button
            component={NavLink}
            to="/my-collection"
            variant="containedRounded"
          >
            GO TO MY CARDS
          </Button>
          <Button
            onClick={() => setOpen(false)}
            variant="containedRounded"
            autoFocus
          >
            CLOSE
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
