import { useState } from "react";
import { Button, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";

export default function Play() {
  const [ended, setEnded] = useState(false);
  return (
    <Box>
      {ended ? (
        <Box
          sx={{
            height: "66vh",
            width: "100%",
            backgroundImage: "url(../coming_soon.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        ></Box>
      ) : (
        <video
          controls
          style={{ width: "100%" }}
          autoPlay
          onEnded={() => setEnded(true)}
        >
          <source
            src="https://shiryo-media.s3.eu-west-1.amazonaws.com/Shiryo_Gameplay_Footage-2.mp4"
            type="video/mp4"
          ></source>
        </video>
      )}
    </Box>
    // <Box
    //   sx={{
    //     height: "66vh",
    //     width: "100%",
    //     backgroundImage: "url(../coming_soon.png)",
    //     backgroundRepeat: "no-repeat",
    //     backgroundSize: "cover",
    //     backgroundPosition: "center center",
    //   }}
    // >
    //   {/* <Button variant="contained" size="large" sx={{ position: "absolute", top: "50%", left: "50%" }}>
    //     PLAY
    //   </Button> */}
    // </Box>
  );
}
