import React, { useState, useEffect } from "react";

import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import {
  founderPackAddress,
  marketContractAddress,
  marketContract,
  web3,
} from "../contracts/ContractProvider";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { marketContractABI } from "../contracts/contractABI";
import Filter from "../Components/Filter";
import OfferCard from "../Components/OfferCard";
import OfferAvatar from "../Components/OfferAvatar";
import OfferPacks from "../Components/OfferPacks";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import ConnectWallet from "../Components/ConnectWallet";

const SORTOPTIONS = ["Price: Low to High", "Price: High to Low"];

export default function MyListings(props) {
  const [loading, setLoading] = useState(true);
  const [gasPrice, setGasPrice] = React.useState(2.0);

  const [query, setQuery] = useState("");
  const [filterParam, setFilterParam] = useState("all");

  const [searchParam] = useState(["card_name", "description"]);

  const [error, setError] = useState(null);
  const [items, setItems] = useState(null);

  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3;

  function search(items) {
    return items.filter((item) => {
      if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      }
    });
  }

  const acceptItemOffer = async (offerId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );
    //console.log(offerId);
    try {
      await MarketWrite.methods
        .acceptItemOffer(offerId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              setItems(items.filter((item) => item.card.offerId !== offerId));
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const unListItem = async (itemId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    try {
      await MarketWrite.methods
        .cancelMarketItem(itemId)
        .send({ from: props.account ,
        
          gasPrice: parseInt(gasPrice * 10 ** 9)
        
        
        }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              setItems(items.filter((item) => item.card.itemId !== itemId));

            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortBy = (sortBy) => {
    switch (sortBy) {
      case "Price: Low to High":
        const lowToHigh = [...items].sort((a, b) => {
          return a.card.price - b.card.price;
        });
        setItems(lowToHigh);

        break;
      case "Price: High to Low":
        const highToLow = [...items].sort((a, b) => {
          return b.card.price - a.card.price;
        });
        setItems(highToLow);

        break;
      default:
        break;
    }
  };

  const fetchItems = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const marketItems = await marketContract.methods
      .getMarketItemsBySeller(props.account)
      .call();

    setItems([]);
    marketItems.map(async (listing) => {
      if (listing.category === "Avatars") {
        fetch(`https://api.avatars.shiryoinu.com/${listing.tokenId}`)
          .then((res) => res.json())
          .then(async (result) => {
            if (result.error !== 1) {
              result.card = listing;
              result.category = listing.category;

              const marketItem = await marketContract.methods
                .idToMarketItem(listing.itemId)
                .call();

              const itemOffers = await marketContract.methods
                .getItemOffers(marketItem.itemId)
                .call();

              ////console.log(itemOffers);
              result.hasHighestOffer = false;
              if (itemOffers.length > 0) {
                result.hasHighestOffer = true;

                const highestOffer = itemOffers.reduce((prev, current) =>
                  prev.offerAmount > current.offerAmount ? prev : current
                );

                ////console.log(highestOffer)
                result.highestOffer = highestOffer;
              }
              result.listPrice = marketItem.price;
              //console.log(result);
              !marketItem.cancelled && !marketItem.accepted && setItems((items) => [...items, result]);
            }
          });
      } else if (listing.category === "Cards") {
        fetch(`https://api.cards.shiryo.com/${listing.tokenId}.json`)
          .then((res) => res.json())
          .then(async (result) => {
            if (result.error !== 1) {
              result.card = listing;
              result.category = listing.category;

              const marketItem = await marketContract.methods
                .idToMarketItem(listing.itemId)
                .call();
              const itemOffers = await marketContract.methods
                .getItemOffers(marketItem.itemId)
                .call();

              result.hasHighestOffer = false;
              if (itemOffers.length > 0) {
                result.hasHighestOffer = true;

                const highestOffer = itemOffers.reduce((prev, current) =>
                  prev.offerAmount > current.offerAmount ? prev : current
                );

                ////console.log(highestOffer)
                result.highestOffer = highestOffer;
              }
              result.listPrice = marketItem.price;
              ////console.log(result);
              !marketItem.cancelled && !marketItem.accepted && setItems((items) => [...items, result]);
            }
          });
      } else {
        let result = {};
        result.category = listing.category;
        result.card = listing;

        result.type = listing.tokenContract === founderPackAddress ? "founders" : "base";
        result.description = result.card.amount + " Shiryo " + result.type + " packs"

        const marketItem = await marketContract.methods
          .idToMarketItem(listing.itemId)
          .call();
        const itemOffers = await marketContract.methods
          .getItemOffers(marketItem.itemId)
          .call();

        result.hasHighestOffer = false;
        if (itemOffers.length > 0) {
          result.hasHighestOffer = true;

          const highestOffer = itemOffers.reduce((prev, current) =>
            prev.offerAmount > current.offerAmount ? prev : current
          );

          ////console.log(highestOffer)
          result.highestOffer = highestOffer;
        }
        result.listPrice = marketItem.price;

        !marketItem.cancelled && !marketItem.accepted && setItems((items) => [...items, result]);
      }
    });
  };

  const fetchGasPrice = async () => {
    fetch("https://gasstation-mainnet.matic.network")
      .then((response) => response.json())
      .then((json) => {
         console.log(json.standard)
        setGasPrice(parseFloat(json.standard));
      });
  };

  useEffect(() => {
    try {
      fetchItems();
      fetchGasPrice();
    } catch (e) {
      setError(e);
    }
  }, [props.account]);

  useEffect(() => {
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      {props.account ? (
        <>
          <Filter
            title="MY LISTINGS"
            setQuery={setQuery}
            filterParam={filterParam}
            setFilterParam={setFilterParam}
            setSortBy={handleSortBy}
            sortOptions={SORTOPTIONS}
          />
          {error && error}
          {items && items.length === 0 && (
            <Typography textAlign="center" sx={{ mt: 5 }}>
              No listings yet
            </Typography>
          )}

          {items &&
            search(items).map((item, index) => {
              return (
                <Paper
                  key={index}
                  sx={{
                    my: 3,
                    background: "rgba(20, 22, 40, 0.8)",
                    border: "1px solid rgba(56,59,77,0.3)",
                    borderRadius: "10px",
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item sx={{ zIndex: 9999 }} xs={12} md={2}>
                      {item.category === "Cards" ? (
                        <OfferCard
                          item={item}
                          index={index}
                          isLoaded={true}
                          market={true}
                          price={web3.utils.fromWei(item.listPrice)}
                        />
                      ) : item.category === "Avatars" ? (
                        <OfferAvatar
                          item={item}
                          index={index}
                          isLoaded={true}
                          market={true}
                          price={web3.utils.fromWei(item.listPrice)}
                        />
                      ) : <OfferPacks item={item}
                        index={index}
                        isLoaded={true}
                        market={true}
                      />}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        py: { xs: 3, md: 3 },
                        px: { xs: 3, md: 5 },
                        width: "100%",
                      }}
                      xs={12}
                      md={10}
                    >
                      <Stack>
                        <Typography variant="playSubtitle">ABOUT</Typography>
                        {item.description.length > 250 ? (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ py: 1 }}
                          >
                            {item.description.substring(0, 250)}...
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ py: 1 }}
                          >
                            {item.description}
                          </Typography>
                        )}
                        <Paper
                          sx={{
                            background: "rgba(19, 19, 34, 1)",
                            p: { sm: 3, xs: 0 },
                            mt: 3,
                          }}
                        >
                          <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                LIST PRICE:
                              </Typography>
                              <Typography variant="body2">
                                {item.card.price &&
                                  web3.utils.fromWei(item.card.price)}{" "}
                                MATIC
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                HIGHEST OFFER:
                              </Typography>
                              {item.hasHighestOffer ? (
                                <Typography variant="body2">
                                  {item.highestOffer &&
                                    parseFloat(
                                      web3.utils.fromWei(
                                        item.highestOffer.offerAmount
                                      )
                                    ).toFixed(2)}{" "}
                                  MATIC
                                </Typography>
                              ) : (
                                <Typography variant="body2">-</Typography>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                justifyContent="flex-end"
                                sx={{ mt: { xs: 3, md: 0 } }}
                              >
                                <Grid
                                  item
                                  sx={{ width: { xs: "100%", md: "auto" } }}
                                >
                                  <Button
                                    variant="containedRounded"
                                    sx={{
                                      width: { xs: "100%", md: "auto" },
                                    }}
                                    onClick={() =>
                                      acceptItemOffer(item.highestOffer.offerId)
                                    }
                                    disabled={!item.highestOffer}
                                  >
                                    ACCEPT OFFER
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  sx={{ width: { xs: "100%", md: "auto" } }}
                                >
                                  <Button
                                    variant="outlinedRounded"
                                    sx={{ width: { xs: "100%", md: "auto" } }}
                                    onClick={() => unListItem(item.card.itemId)}
                                  >
                                    UNLIST
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
        </>
      ) : (
        <ConnectWallet
          subtitle="TO VIEW YOUR LISTINGS"
          walletModal={props.walletModal}
          setWalletModal={props.setWalletModal}
        />
      )}
    </Container>
  );
}
