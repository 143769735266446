import { useEffect, useState } from "react";

import Filter from "../Components/Filter";
import CardGrid from "../Components/CardGrid";
import { Container } from "@mui/material";
import {
  cardsContract,
  avatarsContract,
  founderPackContract,
  normalPackContract,
} from "../contracts/ContractProvider";
import ConnectWallet from "../Components/ConnectWallet";

const SORTOPTIONS = ["Quantity: Low to High", "Quantity: High to Low"];

export default function MyCollection(props) {
  const [query, setQuery] = useState("");
  const [filterParam, setFilterParam] = useState("all");

  const [searchParam] = useState(["card_name", "description"]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [cards, setCards] = useState([]);
  const [userAvatarsBalance, setUserAvatarsBalance] = useState(0);
  const [userAvatarTokensString, setUserAvatarTokensString] = useState("");
  const [ownedAvatars, setOwnedAvatars] = useState([]);
  const [error, setError] = useState(null);

  function search(items) {
    return items.filter((item) => {
      if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      }
    });
  }

  const handleSortBy = (sortBy) => {
    switch (sortBy) {
      case "Quantity: Low to High":
        const lowToHigh = [...items].sort((a, b) => {
          return a.quantity - b.quantity;
        });
        setItems(lowToHigh);

        break;
      case "Quantity: High to Low":
        const highToLow = [...items].sort((a, b) => {
          return b.quantity - a.quantity;
        });
        setItems(highToLow);

        break;
      default:
        break;
    }
  };

  const fetchItems = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const cardBalances = await cardsContract.methods
      .balancesOf(props.account)
      .call();
    setCards(cardBalances);

    setItems([]);
    cardBalances.forEach((quantity, i) => {
      if (quantity > 0) {
        fetch(`https://api.cards.shiryo.com/${i + 1}.json`)
          .then((res) => res.json())
          .then((result) => {
            if (result.error !== 1) {
              result.card = i + 1; // this is setting to the index, it should be result.card =
              result.quantity = quantity;
              result.category = "Cards";
              ////console.log(result);
              setItems((items) => [...items, result]);
            }
          });
      }
    });

    let erc20FounderPacks = await founderPackContract.methods
      .balanceOf(props.account)
      .call();

    let erc20NormalPacks = await normalPackContract.methods
      .balanceOf(props.account)
      .call();

    items &&
      erc20NormalPacks > 0 &&
      setItems((items) => [
        ...items,
        {
          category: "Packs",
          type: "base",
          description: "Base pack",
          quantity: erc20NormalPacks,
        },
      ]);
    items &&
      erc20FounderPacks > 0 &&
      setItems((items) => [
        ...items,
        {
          category: "Packs",
          type: "founders",
          description: "Founders pack",
          quantity: erc20FounderPacks,
        },
      ]);

    const avBalance = await avatarsContract.methods
      .balanceOf(props.account)
      .call();
    setUserAvatarsBalance(avBalance);

    let avTokens = await avatarsContract.methods
      .tokensOfOwner(props.account)
      .call();
    // if (userAvatarTokensString !== JSON.stringify(avTokens)) {
    //   fetchAvatarDetails(avTokens);
    //   setUserAvatarTokensString(JSON.stringify(avTokens));
    // }
    avTokens.forEach(async (r) => {
      let uri = "https://api.avatars.shiryoinu.com/" + r;
      let req = await fetch(uri);
      let res = await req.json();
      if (res.error !== 1) {
        res.category = "Avatars";

        setItems((items) => [...items, res]);
      }
    });
  };

  useEffect(() => {
    try {
      fetchItems();
    } catch (e) {
      setError(e);
    }
    return () => setItems([]);
  }, [props.account]);

  let cardAmount = cards.filter((card) => parseInt(card) > 0).length;
  let avatarAmount = parseInt(userAvatarsBalance);

  if (items.length > 0 && !isLoaded && items) {
    setIsLoaded(true);
  }

  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      {props.account ? (
        <>
          <Filter
            title="MY COLLECTION"
            setQuery={setQuery}
            filterParam={filterParam}
            setFilterParam={setFilterParam}
            setSortBy={handleSortBy}
            sortOptions={SORTOPTIONS}
          />
          {error && error}

          <CardGrid
            items={items}
            error={error}
            isLoaded={isLoaded}
            search={search}
            max={24}
            market={false}
          />
        </>
      ) : (
        <ConnectWallet
          subtitle={"TO VIEW YOUR COLLECTION"}
          walletModal={props.walletModal}
          setWalletModal={props.setWalletModal}
        />
      )}
    </Container>
  );
}
