import Web3 from "web3";
//import { rpcEndpoint } from "../contracts/ContractProvider";
import { httpRPCEndpoint } from "../contracts/ContractProvider";

import TransactionQueueSize from './TransactionQueueSize'

export const useTransactionMonitor = () => {
    let web3 = new Web3(httpRPCEndpoint);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    const expectedBlockTime = 3000;

    const monitorTransaction = async (error, transactonHash, callback) => {
        TransactionQueueSize.increment();

        let transactionReceipt = null
        ////console.log(transactonHash);
        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
            try {
                transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
                //console.log(transactionReceipt);
                await sleep(expectedBlockTime)
            } catch (error) {
                console.log(error)
                transactionReceipt = "dummy"
            }
            await sleep(expectedBlockTime)
        }
        if (transactionReceipt !== "dummy") {
            callback();
        }
        TransactionQueueSize.decrement();
    }

    return {
        monitorTransaction
    };
};

