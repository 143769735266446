import { useEffect, useState } from "react";
import { Button, Typography, TextField, Divider } from "@mui/material";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import GridItemCard from "./GridItemCard";
import GridItemAvatar from "./GridItemAvatar";
import GridItemPack from "./GridItemPack";

export default function CardGrid({
  items,
  error,
  isLoaded,
  search,
  max,
  market,
}) {
  return (
    <>
      {error ? (
        "Error..."
      ) : !isLoaded ? (
        <Typography textAlign="center" py={5}>
          Loading...
        </Typography>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            sx={{ pt: 3, pb: 3 }}
            display="grid"
            gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))"
          >
            {error ? (
              "Error..."
            ) : !isLoaded ? (
              <>Loading...</>
            ) : (
              <>
                {items &&
                  search(items).map((item, index) => (
                    <Grid item key={index} sx={{ margin: "0 auto" }}>
                      {item.category === "Cards" ? (
                        <GridItemCard
                          item={item}
                          index={index}
                          isLoaded={isLoaded}
                          market={market}
                        />
                      ) : item.category === "Packs" ? (
                        <GridItemPack
                          item={item}
                          index={index}
                          isLoaded={isLoaded}
                          market={market}
                        />
                      ) : (<GridItemAvatar
                        item={item}
                        index={index}
                        isLoaded={isLoaded}
                        market={market}
                      />)}
                    </Grid>
                  ))}
              </>
            )}
          </Grid>

          <Grid item xs={12} sx={{ mt: 3, textAlign: "center" }}>
            <Button variant="outlinedRounded">Load more</Button>
          </Grid>
        </>
      )}
    </>
  );
}
