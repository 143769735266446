import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { avatarsContract } from "../contracts/ContractProvider";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import MarketAvatar from "./MarketAvatar";
import GridItemAvatar from "./GridItemAvatar";
import { rarities } from "../rarities";

export default function ViewItem(props) {
  const [item, setItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alsoInCollection, setAlsoInCollection] = useState([]);

  let { id } = useParams();

  const fetchAvatar = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    fetch(`https://api.avatars.shiryoinu.com/${id}`)
      .then((res) => res.json())
      .then((result) => {
        result.card = id;

        setItem(result);
        setIsLoaded(true);
      });

    let avTokens = await avatarsContract.methods
      .tokensOfOwner(props.account)
      .call();

    let maxCount = 0;

    avTokens &&
      avTokens.map((r) => {
        //console.log(r);
        maxCount++;
        if (maxCount <= 6) {
          fetch(`https://api.avatars.shiryoinu.com/${r}`)
            .then((res) => res.json())
            .then((result) => {
              if (result.error !== 1) {
                //console.log(result);
                result.category = "Avatars";
                setAlsoInCollection((alsoInCollection) => [
                  ...alsoInCollection,
                  result,
                ]);
              }
            });
        }
      });
  };
  useEffect(() => {
    fetchAvatar();

    return () => {
      setItem(null);
    };
  }, [id]);

  return (
    isLoaded &&
    item && (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              GO BACK
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="containedRounded"
              component={NavLink}
              to="/avatars"
            >
              BUY MORE
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <img
              src={item.image}
              alt={item.name}
              style={{
                width: "100%",
                borderRadius: "100%",
                padding: "16px",
              }}
            />
            <Stack textAlign="center" sx={{ mt: 2 }}>
              <Typography sx={{ color: "#46E168", fontWeight: "bold" }}>
                IN YOUR COLLECTION
              </Typography>
              <Typography variant="body2" color="text.secondary">
                1 Currently Owned
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                borderRadius: "10px",
                background: "rgba(23, 24, 40, 1)",
              }}
            >
              <Stack>
                <Stack
                  sx={{
                    py: 3,
                    px: 7,
                    background:
                      "url('../top.png') no-repeat center center / cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      #{item.edition}
                    </Typography>

                    <Typography
                      sx={{
                        color: rarities["mythic"],
                        fontWeight: "bold",
                      }}
                    >
                      MYTHIC
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      QTY: 1
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      AVATAR
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ px: 7, py: 3 }}>
                  <MarketAvatar item={item} account={props.account} />
                  <Typography variant="playSubtitle">ABOUT</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ py: 1, wordBreak: "break-word" }}
                  >
                    {item.description}
                  </Typography>
                  {/* <Divider sx={{ my: 3 }} />
                  <Typography variant="playSubtitle">PROPERTIES</Typography>
                  <Grid
                    spacing={1}
                    container
                    display="grid"
                    sx={{ py: 1 }}
                    gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                  >
                    {item.attributes.map((attr) => (
                      <Grid item sx={{ width: "100% !important" }}>
                        <Paper
                          variant="outlined"
                          sx={{ background: "none", p: 2, height: "100%" }}
                        >
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {attr.value === "blank" ? "No" : attr.value}{" "}
                            {attr.trait_type}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            0.1% of avatars have this trait
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid> */}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">ALSO IN YOUR COLLECTION</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              VIEW ALL
            </Button>
          </Grid>
        </Grid>
        {alsoInCollection &&
          <>
            <Divider sx={{ my: 3 }} />
            <Grid container
              spacing={2}
              sx={{ pt: 3, pb: 3 }}
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))">
              {alsoInCollection.map(
                (alsoInCollection, index) =>
                  index < 6 && (
                    <Grid key={index} item sx={{ margin: "0 auto" }}>
                      <GridItemAvatar item={alsoInCollection} index={index} />
                    </Grid>
                  )
              )}
            </Grid>
          </>
        }
      </Container>
    )
  );
}
