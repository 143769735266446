import { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Stack,
  Container,
  Grid,
  Button,
  Dialog,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import Cookies from "js-cookie";

import MCCountdown from "../utils/MCCountdown";
import CircularProgress from "@mui/material/CircularProgress";
import { useInterval } from "../hooks/useInterval";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import { avatarsContract } from "../contracts/ContractProvider";

const commaFormat = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ChangeAvatarDialog = ({
  setDialogOpen,
  open,
  onClose,
  currentAvatar,
  changeToAvatar,
}) => {
  if (currentAvatar === undefined) {
    currentAvatar = { image: "./placeholder_wolf.png" };
  }

  const handleChangeAvatar = (changeToAvatar) => {
    //console.log(changeToAvatar);
    Cookies.set("current-avatar", JSON.stringify(changeToAvatar));
    setDialogOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack>
        <Grid
          item
          sx={{
            backgroundImage: "url('./avatar_bg.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "128px",
            py: 5,
            mb: 5,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <img
                style={{
                  borderRadius: "100%",
                  width: "50%",
                  margin: "0 auto",
                }}
                src={currentAvatar.image}
                alt="Wolf"
              />
            </Grid>
            <CachedIcon
              sx={{
                position: "absolute",
                fontSize: "3rem",
                top: "16px",
                left: "50%",
                transform: "translate(-50%, 0)",
                color: "rgba(255,255,255,0.25)",
              }}
            />
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <img
                style={{
                  borderRadius: "100%",
                  width: "50%",
                  margin: "0 auto",
                }}
                src={changeToAvatar.image}
                alt="Wolf"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ p: 5, textAlign: "center" }}>
          <Stack>
            <Typography variant="h3">SWITCH AVATAR</Typography>

            <Grid container width="100%" sx={{ py: 3 }}>
              <Grid item xs={5}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeAvatar(changeToAvatar)}
                >
                  SWITCH
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setDialogOpen(false)}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default function Profile(props) {
  // //console.log(props);

  //const [avatar, setAvatar] = useState(0);

  const [mintAmount, setMintAmount] = useState(1);
  const [changeToAvatar, setChangeToAvatar] = useState(0);

  const [ownedAvatars, setOwnedAvatars] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [tokenNeedsApproval, setTokenNeedsApproval] = useState(true);

  const [userAvatarsBalance, setUserAvatarsBalance] = useState(0);
  const [userAvatarTokensString, setUserAvatarTokensString] = useState("");
  const CONTRACT_POLLING_INTERVAL = 5500; // millis
  const { monitorTransaction } = useTransactionMonitor();

  const [timerFinished, setTimerfinished] = useState(false);

  const REVEAL_TIME = 1638302400;

  useInterval(() => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  const fetchData = async () => {
    if (
      props.account !== undefined ||
      props.account !== null ||
      props.account !== false
    ) {
      try {
        const avBalance = await avatarsContract.methods
          .balanceOf(props.account)
          .call();
        setUserAvatarsBalance(avBalance);

        let avTokens = await avatarsContract.methods
          .tokensOfOwner(props.account)
          .call();
        if (userAvatarTokensString !== JSON.stringify(avTokens)) {
          fetchAvatarDetails(avTokens);
          setUserAvatarTokensString(JSON.stringify(avTokens));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchAvatarDetails = async (avTokens) => {
    avTokens.forEach(async (r) => {
      //console.log(r);
      let uri = "https://api.avatars.shiryoinu.com/" + r;
      let req = await fetch(uri);
      let res = await req.json();
      //console.log(res);
      if (res.error !== 1) {
        //console.log(res);
        setOwnedAvatars((ownedAvatars) => [...ownedAvatars, res]);
      }
    });
  };

  useEffect(() => {
    const nowInSeconds = Math.floor(new Date().getTime() / 1000);
    ////console.log(nowInSeconds)
    if (nowInSeconds >= REVEAL_TIME) {
      setTimerfinished(true);
    } else {
      ////console.log(REVEAL_TIME-nowInSeconds);
    }

    fetchData();
  }, []);

  const handleAvatarClick = (isSelected, newAvatar) => {
    if (!isSelected) {
      setChangeToAvatar(newAvatar);
      setDialogOpen(true);
    }
  };

  let wolfArray = [];

  for (let index = 0; index < ownedAvatars.length; index++) {
    let bg = "";

    const thisAvatar = ownedAvatars[index];

    const isSelected =
      props.selectedAvatar !== undefined &&
      props.selectedAvatar.edition === thisAvatar.edition;

    isSelected ? (bg = "rgba(20, 22, 40, 1)") : (bg = "rgba(17, 17, 32, 1)");

    wolfArray.push(
      <Grid item>
        <Paper
          onClick={() => handleAvatarClick(isSelected, thisAvatar)}
          className="shiryo-avatar"
          variant="outlined"
          sx={{
            p: 3,
            width: { xs: "96px", sm: "175px" },
            height: { xs: "96px", sm: "175px" },
            textAlign: "center",
            background: bg,
            position: "relative",
            cursor: "pointer",
          }}
        >
          {isSelected && (
            <div
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                width: "32px",
                height: "32px",
                background: "rgba(43, 67, 194, 1)",
                borderRadius: "0px 5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Check />
            </div>
          )}

          <img
            style={{
              borderRadius: "100%",
              width: "100%",
              margin: "0 auto",
            }}
            src={thisAvatar.image}
            alt="Wolf"
          />
        </Paper>
      </Grid>
    );
  }

  // all that pushing means we need to reverse it

  return (
    <div
      style={{
        backgroundImage: "url('./bg.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "286px",
      }}
    >
      <ChangeAvatarDialog
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        onClose={() => setDialogOpen(false)}
        //avatar={avatar}
        //setAvatar={setAvatar}
        changeToAvatar={changeToAvatar}
        currentAvatar={props.selectedAvatar}
      />
      <Container maxWidth="lg" sx={{ pt: 3, pb: 3 }}>
        <Grid container sx={{ p: 3 }}>
          <Grid item>
            <Typography variant="h1">Profile</Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", pt: 1 }}>
              CUSTOMISE HOW PEOPLE SEE YOU IN-GAME
            </Typography>
          </Grid>
        </Grid>

        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper sx={{ background: "rgba(20, 22, 40, 1)" }}>
              <Stack sx={{ p: 5 }}>
                <Grid item sx={{ textAlign: "center", mb: 2 }}>
                  {props.selectedAvatar !== undefined ? (
                    <img
                      style={{
                        borderRadius: "100%",
                        width: "100%",
                        maxWidth: "176px",
                        margin: "0 auto",
                      }}
                      src={props.selectedAvatar.image}
                      alt="Wolf"
                    />
                  ) : (
                    <img
                      style={{
                        borderRadius: "100%",
                        width: "100%",
                        maxWidth: "176px",
                        margin: "0 auto",
                      }}
                      src="./placeholder_wolf.png"
                      alt="Wolf"
                    />
                  )}
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <Paper
                    elevation={0}
                    sx={{
                      background: "#24293c",
                      borderRadius: "20px",
                      width: "120px",
                      margin: "0 auto",
                      textAlign: "center",
                      padding: "10px 20px",
                      fontSize: "12px",
                    }}
                  >
                    {props.account && props.account.substr(0, 6)}...
                    {props.account &&
                      props.account.substr(
                        props.account && props.account.length - 3,
                        3
                      )}
                  </Paper>
                </Grid>
                {/* <Grid item sx={{ mb: 3 }}>
                  <Typography variant="h5">PACKS OWNED</Typography>
                  <Typography variant="subtitle1" sx={{ pt: 1 }}>
                    20 UNOPENED
                  </Typography>
                </Grid> */}
                {/* <Grid item sx={{ mb: 2 }}>
                  <Typography variant="h5">CARDS COLLECTED</Typography>
                  <Typography variant="subtitle1" sx={{ pt: 1 }}>
                    0 OF 150
                  </Typography>
                </Grid> */}
                <Grid item sx={{ mb: 3 }}>
                  <Typography variant="h5">AVATARS OWNED</Typography>
                  <Typography variant="subtitle1" sx={{ pt: 1 }}>
                    {userAvatarsBalance ? (
                      userAvatarsBalance + " AVATARS"
                    ) : (
                      <CircularProgress size={8} />
                    )}{" "}
                  </Typography>
                </Grid>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Paper
              sx={{
                height: "100%",
                background: "rgba(17, 17, 32, 1)",
                p: { xs: 0, md: 3 },
              }}
            >
              {timerFinished ? (
                <>
                  <Grid
                    container
                    sx={{ p: 3, pb: 0, pt: 3 }}
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Typography variant="h3">YOUR AVATARS </Typography>
                      <Typography variant="subtitle1" sx={{ pt: 1 }}>
                        YOUR DIGITAL PERSONA
                      </Typography>
                    </Grid>
                    {/* <Grid item sx={{ pt: { xs: 3, md: 0 } }}>
                      <Grid container spacing={3}>
                        <Grid item>
                          <Button size="sm" variant="outlined">
                            OWNED
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>

                  {userAvatarsBalance ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{ p: 3, justifyContent: "flex-start" }}
                    >
                      {wolfArray}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        p: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      <Stack
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress size={24} />
                        <Typography sx={{ pl: 3 }} variant="subtitle2">
                          Loading your avatars...
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid
                  container
                  sx={{
                    p: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Stack>
                    <Typography
                      variant="h3"
                      sx={{ textAlign: "center", mb: 3 }}
                    >
                      YOUR AVATARS
                      <br /> WILL BE REVEALED IN
                    </Typography>
                    <MCCountdown endDate={parseInt(REVEAL_TIME)} />
                  </Stack>
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
