import { useState, useEffect } from "react";
import {
  Paper,
  Typography,
  Stack,
  Container,
  Grid,
  Button,
  Dialog,
  Box,
  TextField,
} from "@mui/material";
import Check from "@mui/icons-material/Check";
import CachedIcon from "@mui/icons-material/Cached";
import Cookies from "js-cookie";

import MCCountdown from "../utils/MCCountdown";

import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { useInterval } from "../hooks/useInterval";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import {
  avatarsContract,
  avatarsContractAddress,
  baseTokenContractAddress,
  web3,
  baseTokenContract,
} from "../contracts/ContractProvider";
import { AvatarsABI, IERC20 } from "../contracts/contractABI";

const commaFormat = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const ChangeAvatarDialog = ({
  setDialogOpen,
  open,
  onClose,
  currentAvatar,
  changeToAvatar,
}) => {
  if (currentAvatar === undefined) {
    currentAvatar = { image: "./placeholder_wolf.png" };
  }

  const handleChangeAvatar = (changeToAvatar) => {
    //console.log(changeToAvatar);
    Cookies.set("current-avatar", JSON.stringify(changeToAvatar));
    setDialogOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack>
        <Grid
          item
          sx={{
            backgroundImage: "url('./avatar_bg.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "128px",
            py: 5,
            mb: 5,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <img
                style={{
                  borderRadius: "100%",
                  width: "50%",
                  margin: "0 auto",
                }}
                src={currentAvatar.image}
                alt="Wolf"
              />
            </Grid>
            <CachedIcon
              sx={{
                position: "absolute",
                fontSize: "3rem",
                top: "16px",
                left: "50%",
                transform: "translate(-50%, 0)",
                color: "rgba(255,255,255,0.25)",
              }}
            />
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <img
                style={{
                  borderRadius: "100%",
                  width: "50%",
                  margin: "0 auto",
                }}
                src={changeToAvatar.image}
                alt="Wolf"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ p: 5, textAlign: "center" }}>
          <Stack>
            <Typography variant="h3">SWITCH AVATAR</Typography>

            <Grid container width="100%" sx={{ py: 3 }}>
              <Grid item xs={5}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangeAvatar(changeToAvatar)}
                >
                  SWITCH
                </Button>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={5}>
                <Button
                  sx={{ width: "100%" }}
                  variant="outlined"
                  color="primary"
                  onClick={() => setDialogOpen(false)}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Stack>
    </Dialog>
  );
};

export default function Profile(props) {
  // //console.log(props);

  //const [avatar, setAvatar] = useState(0);

  const [mintAmount, setMintAmount] = useState(1);
  const [changeToAvatar, setChangeToAvatar] = useState(0);

  const [ownedAvatars, setOwnedAvatars] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [tokenNeedsApproval, setTokenNeedsApproval] = useState(true);
  const [shiryoPrice, setShiryoPrice] = useState(0);
  const [ethMintPrice, setEthMintPrice] = useState(15000000000000000);
  const [userEthBalance, setUserEthBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userAvatarsBalance, setUserAvatarsBalance] = useState(0);
  const [userAvatarTokensString, setUserAvatarTokensString] = useState("");
  const CONTRACT_POLLING_INTERVAL = 5500; // millis
  const { monitorTransaction } = useTransactionMonitor();

  const onApproveClicked = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    //console.log("approving " + baseTokenContractAddress);
    const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)

    const TokenContractWrite = new web3I.eth.Contract(
      IERC20,
      baseTokenContractAddress
    );
    const amount =
      "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    //const amountWei = web3.utils.toWei(amount, "ether");

    const estimatedGas = await TokenContractWrite.methods
      .approve(avatarsContractAddress, amount)
      .estimateGas({ from: props.account });

    const result = TokenContractWrite.methods
      .approve(avatarsContractAddress, amount)
      .send(
        { from: props.account, gas: estimatedGas + 500 },
        (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => { });
          }
        }
      )
      .catch(() => { });
    return result;
  };

  const mint_avatars_for_eth = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)
    const NFTWrite = new web3I.eth.Contract(AvatarsABI, avatarsContractAddress); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const ethValue = mintAmount * parseInt(ethMintPrice);
      ////console.log(ethValue)
      const estimatedGas = await NFTWrite.methods
        .mint_avatars_for_eth(mintAmount)
        .estimateGas({ from: props.account, value: ethValue.toString() });
      ////console.log(estimatedGas);

      await NFTWrite.methods
        .mint_avatars_for_eth(mintAmount)
        .send(
          {
            from: props.account,
            value: ethValue.toString(),
            gas: estimatedGas + 10000,
          },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => { });
            }
          }
        )
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const mint_avatars_for_shiryo = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)
    const NFTWrite = new web3I.eth.Contract(AvatarsABI, avatarsContractAddress); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const estimatedGas = await NFTWrite.methods
        .mint_avatars_for_shiryo(mintAmount)
        .estimateGas({ from: props.account });
      ////console.log(estimatedGas);

      await NFTWrite.methods
        .mint_avatars_for_shiryo(mintAmount)
        .send(
          { from: props.account, gas: estimatedGas + 10000 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => { });
            }
          }
        )
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  useInterval(() => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  const fetchData = async () => {
    // this can be done read only
    const mintPriceEth = await avatarsContract.methods.mintPriceEth().call();
    const tokensPerAvatar = await avatarsContract.methods
      .getTokensForEth(mintPriceEth)
      .call();
    setShiryoPrice(parseInt(tokensPerAvatar));
    setEthMintPrice(parseInt(mintPriceEth));

    if (
      props.account !== undefined ||
      props.account !== null ||
      props.account !== false
    ) {
      try {
        const ethBalance = await web3.eth.getBalance(props.account);
        setUserEthBalance(web3.utils.fromWei(ethBalance.toString()));
        const tokenBalance = await baseTokenContract.methods
          .balanceOf(props.account)
          .call();
        setUserTokenBalance(tokenBalance * 10 ** -9);
        const avBalance = await avatarsContract.methods
          .balanceOf(props.account)
          .call();
        setUserAvatarsBalance(avBalance);

        if (tokenNeedsApproval === true) {
          let tokenAllowance = await baseTokenContract.methods
            .allowance(props.account, avatarsContractAddress)
            .call();
          if (tokenAllowance > 0) {
            setTokenNeedsApproval(false);
          }
        }
        let avTokens = await avatarsContract.methods
          .tokensOfOwner(props.account)
          .call();
        if (userAvatarTokensString !== JSON.stringify(avTokens)) {
          fetchAvatarDetails(avTokens);
          setUserAvatarTokensString(JSON.stringify(avTokens));
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchAvatarDetails = async (avTokens) => {
    avTokens.forEach(async (r) => {
      //console.log(r);
      let uri = "https://api.avatars.shiryoinu.com/" + r;
      let req = await fetch(uri);
      let res = await req.json();
      if (res.error !== 1) {
        ////console.log(res);
        setOwnedAvatars((ownedAvatars) => [...ownedAvatars, res]);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onMintAmountInputChanged = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (rx_live.test(evt.target.value)) {
      const amt = evt.target.value;
      setMintAmount(amt);
    }
  };

  const handleAvatarClick = (isSelected, newAvatar) => {
    if (!isSelected) {
      setChangeToAvatar(newAvatar);
      setDialogOpen(true);
    }
  };

  let wolfArray = [];

  for (let index = 0; index < ownedAvatars.length; index++) {
    let bg = "";

    const thisAvatar = ownedAvatars[index];

    const isSelected =
      props.selectedAvatar !== undefined &&
      props.selectedAvatar.edition === thisAvatar.edition;

    isSelected ? (bg = "rgba(20, 22, 40, 1)") : (bg = "rgba(17, 17, 32, 1)");

    wolfArray.push(
      <Paper
        onClick={() => handleAvatarClick(isSelected, thisAvatar)}
        className="shiryo-avatar"
        variant="outlined"
        sx={{
          p: 3,
          mb: 2,
          width: { xs: "96px", sm: "175px" },
          height: { xs: "96px", sm: "175px" },
          textAlign: "center",
          background: bg,
          position: "relative",
          cursor: "pointer",
        }}
      >
        {isSelected && (
          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              width: "32px",
              height: "32px",
              background: "rgba(43, 67, 194, 1)",
              borderRadius: "0px 5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Check />
          </div>
        )}

        <img
          style={{
            borderRadius: "100%",
            width: "100%",
            margin: "0 auto",
          }}
          src={thisAvatar.image}
          alt="Wolf"
        />
      </Paper>
    );
  }

  // all that pushing means we need to reverse it

  return (
    <div
    // style={{
    //   backgroundImage: "url('./bg.jpg')",
    //   backgroundRepeat: "no-repeat",
    //   backgroundPosition: "center",
    //   backgroundSize: "cover",
    //   height: "286px",
    // }}
    >
      <ChangeAvatarDialog
        open={dialogOpen}
        setDialogOpen={setDialogOpen}
        onClose={() => setDialogOpen(false)}
        //avatar={avatar}
        //setAvatar={setAvatar}
        changeToAvatar={changeToAvatar}
        currentAvatar={props.selectedAvatar}
      />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container sx={{ pt: 3, px: 3 }} justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">MINT AVATAR NFT's</Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", pt: 1 }}>
              GET YOUR SHIRYO NFT'S
            </Typography>
          </Grid>
        </Grid>
        <Paper variant="outlined" sx={{ m: 3 }}>
          <Grid container sx={{ p: 3 }} justifyContent="space-between">
            <Grid item xs={12} md={5}>
              <Stack sx={{ py: 3 }}>
                <img
                  style={{
                    borderRadius: "100%",
                    width: "100%",
                    maxWidth: "176px",
                    margin: "0 auto",
                  }}
                  src="./placeholder_wolf.png"
                  alt="Wolf"
                />
                <Grid item sx={{ my: 3, textAlign: "center" }}>
                  <Typography variant="subtitle1">
                    SELECT AMOUNT TO MINT
                  </Typography>
                </Grid>
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <Button
                      sx={{
                        width: { xs: "32px", sm: "64px" },
                        minWidth: { xs: "32px", sm: "64px" },
                      }}
                      variant="contained"
                      onClick={() =>
                        setMintAmount(
                          mintAmount === 1 ? mintAmount : mintAmount - 1
                        )
                      }
                    >
                      -1
                    </Button>
                  </Grid>

                  <Grid item sx={{ textAlign: "center" }}>
                    <TextField
                      sx={{
                        width: { xs: "72px", sm: "120px" },
                        background: "black",
                        borderTop: "1px solid #2b43c2",
                        borderBottom: "1px solid #2b43c2",
                      }}
                      //label="Enter amount"
                      type="number"
                      id="mintAmountInput"
                      variant="standard"
                      inputProps={{
                        min: 1,
                        step: 1,
                        inputMode: "numeric",
                      }}
                      value={mintAmount}
                      onChange={onMintAmountInputChanged}
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      sx={{
                        width: { xs: "32px", sm: "64px" },
                        minWidth: { xs: "32px", sm: "64px" },
                      }}
                      variant="contained"
                      onClick={() => {
                        setMintAmount(parseInt(mintAmount) + 5);
                      }}
                    >
                      +5
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <Stack sx={{ px: { xs: 0, sm: 3 } }}>
                <Typography variant="h3" sx={{ mt: 3, ml: 0, mr: "auto" }}>
                  MINT WITH MATIC
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ ml: 0, mr: "auto", mb: 3 }}
                >
                  BALANCE: {parseFloat(userEthBalance).toFixed(2)} MATIC
                </Typography>
                <Grid container width="100%">
                  <Grid item xs={12} sm={5}>
                    {tokenNeedsApproval ? (
                      <Button
                        onClick={onApproveClicked}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                      >
                        APPROVE
                      </Button>
                    ) : (
                      <Button
                        onClick={mint_avatars_for_eth}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        disabled={
                          parseFloat(userEthBalance).toFixed(4) <
                          Math.round(
                            mintAmount *
                            parseFloat(
                              web3.utils.fromWei(ethMintPrice.toString())
                            ) *
                            1000
                          ) /
                          1000
                        }
                      >
                        MINT NOW
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box
                      sx={{
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                        py: { xs: 1, sm: 0 },
                      }}
                    >
                      <Typography variant="subtitle1">
                        {Math.round(
                          mintAmount *
                          parseFloat(
                            web3.utils.fromWei(ethMintPrice.toString())
                          ) *
                          1000
                        ) / 1000}{" "}
                        MATIC
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Typography
                  variant="h3"
                  sx={{ mt: 3, ml: 0, mr: "auto", pt: 3 }}
                >
                  MINT WITH SHIRYO TOKENS
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ ml: 0, mr: "auto", mb: 3 }}
                >
                  BALANCE: {commaFormat(userTokenBalance.toFixed(0))} SHIRYO
                </Typography>
                <Grid container width="100%">
                  <Grid item xs={12} sm={5}>
                    {tokenNeedsApproval ? (
                      <Button
                        onClick={onApproveClicked}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                      >
                        APPROVE
                      </Button>
                    ) : (
                      <Button
                        onClick={mint_avatars_for_shiryo}
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        disabled={
                          parseInt(userTokenBalance) < mintAmount * shiryoPrice
                        }
                      >
                        MINT NOW
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box
                      sx={{
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                        py: { xs: 1, sm: 0 },
                      }}
                    >
                      <Typography variant="subtitle1">
                        {commaFormat((mintAmount * shiryoPrice).toFixed(0))}{" "}
                        SHIRYO
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid item xs={12}>
            <video controls style={{ width: "100%", borderRadius: "16px" }}>
              <source
                src="https://shiryo-media.s3.eu-west-1.amazonaws.com/SHIRYO_INU_AVATAR_NFTS_PREVIEW.mp4"
                type="video/mp4"
              ></source>
            </video>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
