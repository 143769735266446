import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { cardsContract } from "../contracts/ContractProvider";
import GridItemCard from "./GridItemCard";
import MarketCard from "./MarketCard";
import { HelpOutline } from "@mui/icons-material";
import { rarities } from "../rarities";

export default function ViewItem(props) {
  const [item, setItem] = useState(null);
  const [owner, setOwner] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alsoInCollection, setAlsoInCollection] = useState([]);

  let { id } = useParams();

  const fetchCard = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    let cardBalances = await cardsContract.methods
      .balancesOf(props.account)
      .call();

    fetch(`https://api.cards.shiryo.com/card/${id}.json`)
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== 1) {
          result.number = id;
          result.quantity = cardBalances[parseInt(id - 1)];
          setItem(result);
          setIsLoaded(true);
        }
      });
    let maxCount = 0;

    cardBalances &&
      cardBalances.map((cb, i) => {
        if (parseInt(cb) > 0) {
          maxCount++;

          if (maxCount <= 6) {
            fetch(`https://api.cards.shiryo.com/card/${i + 1}.json`)
              .then((res) => res.json())
              .then((result) => {
                if (result.error !== 1) {
                  result.quantity = cb;

                  setAlsoInCollection((alsoInCollection) => [
                    ...alsoInCollection,
                    result,
                  ]);
                }
              });

            // //console.log(i);
            // //console.log(maxCount);
            // //console.log("maxcount reached");
          }
        }
      });
  };

  useEffect(() => {
    fetchCard();
  }, [id]);

  return (
    isLoaded &&
    item && (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              GO BACK
            </Button>
          </Grid>
          <Grid item>
            <Button variant="containedRounded">BUY MORE</Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            {
              (item.animation_url) ?
                <video autoPlay loop poster={`https://s3.eu-west-1.amazonaws.com/cards.shiryo.com/${item.number}.png`} style={{
                  width: "100%",
                  borderRadius: item.category === "Avatars" ? "100%" : "10px",
                }}>
                  <source src={item.animation_url} type="video/webm" />
                </video>
                :
                <img
                  src={`https://s3.eu-west-1.amazonaws.com/cards.shiryo.com/${item.number}.png`}
                  alt={item["Card name"]}
                  style={{
                    width: "100%",
                    borderRadius: item.category === "Avatars" ? "100%" : "10px",
                  }}
                />
            }

            <Stack textAlign="center" sx={{ mt: 2 }}>
              <Typography sx={{ color: "#46E168", fontWeight: "bold" }}>
                IN YOUR COLLECTION
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.quantity} Currently Owned
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{ borderRadius: "10px", background: "rgba(23, 24, 40, 1)" }}
            >
              <Stack>
                <Stack
                  sx={{
                    py: 3,
                    px: { sm: 7, xs: 3 },
                    background:
                      "url('../top.png') no-repeat center center / cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.card_name.toUpperCase()}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: rarities[item.rarity.toLowerCase()],
                      }}
                    >
                      {item.rarity.toUpperCase()}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      {item.frequencey}
                      <Tooltip title="Frequency" placemenet="top-start">
                        <HelpOutline
                          sx={{
                            marginBottom: "-2px",
                            marginLeft: "2px",
                          }}
                          fontSize="14px"
                        />
                      </Tooltip>
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      {item.type.toUpperCase()}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ px: { sm: 7, xs: 3 }, py: 3 }}>
                  <MarketCard item={item} account={props.account} />
                  <Typography variant="playSubtitle">ABOUT</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ py: 1, wordBreak: "break-word" }}
                  >
                    {item.description}
                  </Typography>
                  {/* <Divider sx={{ my: 3 }} />
                  <Typography variant="playSubtitle">PROPERTIES</Typography>
                  <Grid
                    container
                    spacing={1}
                    display="grid"
                    sx={{ py: 1 }}
                    gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                  >
                    <Grid item>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.element}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          10% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.type} Beast</Typography>
                        <Typography variant="body2" color="text.secondary">
                          4% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper
                        variant="outlined"
                        sx={{ background: "none", p: 2, height: "100%" }}
                      >
                        <Typography>{item.rarity} Rarity</Typography>
                        <Typography variant="body2" color="text.secondary">
                          0.1% of beasts have this trait
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid> */}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">ALSO IN YOUR COLLECTION</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              VIEW ALL
            </Button>
          </Grid>
        </Grid>
        {alsoInCollection && (
          <>
            <Divider sx={{ my: 3 }} />
            <Grid
              container
              spacing={2}
              sx={{ py: 3 }}
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))"
            >
              {alsoInCollection.map(
                (alsoInCollection, index) =>
                  index < 6 && (
                    <Grid key={index} item sx={{ margin: "0 auto" }}>
                      <GridItemCard item={alsoInCollection} index={index} />
                    </Grid>
                  )
              )}
            </Grid>
          </>
        )}
      </Container>
    )
  );
}
