import React, { useState, useEffect } from "react";
import { Typography, Container, Grid, Stack, Paper, Button, Divider, TextField, Tooltip, InputAdornment } from "@mui/material";
import Arrow from "../Components/Arrow";
import Launch from "@mui/icons-material/LaunchRounded";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import Web3 from "web3";

import { FarmABI, IERC20 } from "../contracts/contractABI";
import { farmContractAddress, farmContract, stakedTokenContract, stakedLPContract, web3, stakedTokenContractAddress, stakedLPContractAddress } from "../contracts/ContractProvider";
import { useInterval } from "../hooks/useInterval";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";

import MCCountdown from "../utils/MCCountdown";
import { nFormatter } from "../utils/nFormatter";

import { HelpOutline } from "@mui/icons-material";

const commaFormat = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default function Farm(props) {
  ////console.log(props);
  const [tokenNeedsApproval, setTokenNeedsApproval] = useState(true);
  const [lpNeedsApproval, setLPNeedsApproval] = useState(true);
  const [minimumStakedTokens, setMinimumStakedTokens] = useState(20000000000000000000000);
  const [minimumLPTokens, setMinimumLPTokens] = useState(20000000000000000000);

  const [userPosition, setUserPosition] = useState({
    amountToken: 0,
    amountLP: 0,
    totalClaimed: 0,
  });
  const [claimable, setClaimable] = useState(0);
  const [nextRewardTime, setNextRewardTime] = useState(0);
  const [accountTokenBalance, setAccountTokenBalance] = useState(0);
  const [accountLPBalance, setAccountLPBalance] = useState(0);

  const [tokenSupplyAmount, setTokenSupplyAmount] = useState(0);
  const [lpSupplyAmount, setLPSupplyAmount] = useState(0);

  const CONTRACT_POLLING_INTERVAL = 5500; //5500; // millis
  const { monitorTransaction } = useTransactionMonitor();

  const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)
  //const web3I = new Web3(Web3.givenProvider);

  useInterval(() => {
    try {
      fetchData();
    } catch (error) {
      console.log(error);
    }
  }, CONTRACT_POLLING_INTERVAL);

  const fetchData = async () => {
    if (props.account === undefined || props.account === null || props.account === false) return;
    ////console.log("Querying")

    let userPosition = await farmContract.methods.addressToUserPosition(props.account).call();
    ////console.log(userPosition);
    setUserPosition(userPosition);

    let tokenAllowance = await stakedTokenContract.methods.allowance(props.account, farmContractAddress).call();
    ////console.log(tokenAllowance);
    if (parseInt(tokenAllowance) < minimumStakedTokens) {
      setTokenNeedsApproval(true);
    } else {
      setTokenNeedsApproval(false);
    }

    let tokenBalance = await stakedTokenContract.methods.balanceOf(props.account).call();
    setAccountTokenBalance(tokenBalance);

    const lpAllowance = await stakedLPContract.methods.allowance(props.account, farmContractAddress).call();
    if (parseInt(lpAllowance) < minimumLPTokens) {
      setLPNeedsApproval(true);
    } else {
      setLPNeedsApproval(false);
    }

    let lpBalance = await stakedLPContract.methods.balanceOf(props.account).call();
    setAccountLPBalance(lpBalance);

    const claim = await farmContract.methods.claimablePacks(props.account).call();
    setClaimable(claim);

    const nrt = await farmContract.methods.nextRewardTime(props.account).call();
    setNextRewardTime(nrt);
  };

  const fetchOneTimeData = async () => {
    let mst = await farmContract.methods.quantumStakedTokens().call();
    setMinimumStakedTokens(mst);
    let mlpt = await farmContract.methods.quantumLPTokens().call();
    setMinimumLPTokens(mlpt);
  };

  const onApproveTokenClicked = () => {
    onApproveClicked(stakedTokenContractAddress);
  };
  const onApproveLPClicked = () => {
    onApproveClicked(stakedLPContractAddress);
  };
  const onApproveClicked = async (address) => {
    if (props.account === undefined || props.account === null || props.account === false) return;

    //console.log("approving " + address);

    const TokenContractWrite = new web3I.eth.Contract(IERC20, address);
    const amount = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
    //const amountWei = web3.utils.toWei(amount, "ether");

    const estimatedGas = await TokenContractWrite.methods.approve(stakedTokenContractAddress, amount).estimateGas({ from: props.account });

    const result = TokenContractWrite.methods
      .approve(farmContractAddress, amount)
      .send({ from: props.account, gas: estimatedGas + 500 }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => { });
        }
      })
      .catch(() => { });
    return result;
  };

  const onTokenSupplyAmountChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (rx_live.test(evt.target.value)) {
      const supply = evt.target.value;
      setTokenSupplyAmount(supply);
    }
  };

  const onLPSupplyAmountChange = (evt) => {
    const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
    if (rx_live.test(evt.target.value)) {
      const supply = evt.target.value;
      setLPSupplyAmount(supply);
    }
  };

  const onAddTokenClicked = async () => {
    if (props.account === undefined || props.account === null || props.account === false) return;

    // add validation here

    const ShyiroChefWrite = new web3I.eth.Contract(FarmABI, farmContractAddress);
    let tokenSupplyAmountWei = tokenSupplyAmount * 10 ** 12 + "000000000"; // 9 decimals for the token
    ////console.log(tokenSupplyAmountWei);
    const estimatedGas = await ShyiroChefWrite.methods.deposit(stakedTokenContractAddress, tokenSupplyAmountWei).estimateGas({ from: props.account });
    //  //console.log(estimatedGas);
    ShyiroChefWrite.methods
      .deposit(stakedTokenContractAddress, tokenSupplyAmountWei)
      .send({ from: props.account, gas: estimatedGas + 1000 }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => { });
        }
      })
      .catch(() => { });
  };

  const onAddLPClicked = async () => {
    if (props.account === undefined || props.account === null || props.account === false) return;

    if (lpSupplyAmount === 0) return;
    // add validation here

    const ShyiroChefWrite = new web3I.eth.Contract(FarmABI, farmContractAddress);
    let lpSupplyAmountWei = web3.utils.toWei(lpSupplyAmount.toString()); // 18 decimals for the lp
    //console.log(lpSupplyAmountWei);
    const estimatedGas = await ShyiroChefWrite.methods.deposit(stakedLPContractAddress, lpSupplyAmountWei).estimateGas({ from: props.account });
    //  //console.log(estimatedGas);
    ShyiroChefWrite.methods
      .deposit(stakedLPContractAddress, lpSupplyAmountWei)
      .send({ from: props.account, gas: estimatedGas + 1000 }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => { });
        }
      })
      .catch(() => { });
  };

  const onRemoveTokenClicked = () => {
    onRemoveClicked(stakedTokenContractAddress);
  };
  const onRemoveLPClicked = () => {
    onRemoveClicked(stakedLPContractAddress);
  };

  const onRemoveClicked = async (address) => {
    if (props.account === undefined || props.account === null || props.account === false) return;
    //console.log("removing " + address);
    const ShyiroChefWrite = new web3I.eth.Contract(FarmABI, farmContractAddress);

    const estimatedGas = await ShyiroChefWrite.methods.withdraw(address).estimateGas({ from: props.account });
    //console.log(estimatedGas);
    const result = ShyiroChefWrite.methods
      .withdraw(address)
      .send({ from: props.account, gas: estimatedGas + 1000 }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => { });
        }
      })
      .catch(() => { });
    return result;
  };

  const onClaimClicked = async (address) => {
    if (props.account === undefined || props.account === null || props.account === false) return;
    //console.log("claiming ");
    const ShyiroChefWrite = new web3I.eth.Contract(FarmABI, farmContractAddress);

    const estimatedGas = await ShyiroChefWrite.methods.claim().estimateGas({ from: props.account });

    const result = ShyiroChefWrite.methods
      .claim()
      .send({ from: props.account, gas: estimatedGas + 1000 }, (error, txHash) => {
        if (!error) {
          monitorTransaction(error, txHash, async () => { });
        }
      })
      .catch(() => { });
    return result;
  };

  useEffect(() => {
    fetchOneTimeData();

    fetchData();
    return () => {
      //setLoading(false);
    };
  }, []);

  return (
    <div
      style={{
        backgroundImage: "url('./bg.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "286px",
      }}
    >
      <Container maxWidth="lg" sx={{ pt: 5, pb: 3 }}>
        <Grid container sx={{ p: 3 }}>
          <Grid item>
            <Typography variant="h1">FARM</Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", pt: 1, zIndex: 999 }}>
              STAKE TO EARN CARD PACKS
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            gap: 3,
            flexWrap: { md: "nowrap", sm: "wrap" },
            position: "relative",
            zIndex: "2",
          }}
        >
          <img
            src="./wolf.png"
            alt="Shiryo"
            style={{
              position: "absolute",
              zIndex: -1,
              top: "-128px",
              right: "16px",
            }}
          />
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: 3,
              borderRadius: "5px",
              backgroundImage: "url('./farm_bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <Stack>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item sx={{ pr: 3 }}>
                  <img src="./farm_stake_icon_01.png" alt="Shiryo" />
                </Grid>
                <Grid item sx={{ mt: { xs: 3, lg: 0 } }}>
                  <Typography variant="h4">Stake shiryo tokens</Typography>
                  <Typography variant="subtitle1" sx={{ pt: 1 }}>
                    (Each 20T tokens) = 1 FREE PACK PER 7 DAYS
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ my: 3 }}>
                  <a style={{ "text-decoration": "none" }} href="https://swap.shiryo.com/" target="_blank" rel="noreferrer">
                    <Button variant="contained" sx={{ width: "100%", background: "rgba(17, 17, 32, 1)" }}>
                      BUY TOKEN <Launch style={{ height: "12px" }} />{" "}
                    </Button>
                  </a>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  p: 3,
                  background: "rgba(17, 17, 32, 0.7)",
                  borderRadius: "5px",
                }}
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} lg={6} sx={{ pl: 1 }}>
                  <Stack>
                    <Typography variant="h4">STAKE TOKENS</Typography>
                    <TextField
                      sx={{ width: "100%" }}
                      label="Enter amount"
                      type="number"
                      variant="standard"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">TRILLION</InputAdornment>,
                      }}
                      inputProps={{
                        min: 20,
                        step: 20,
                        inputMode: "numeric",
                      }}
                      value={tokenSupplyAmount}
                      onChange={onTokenSupplyAmountChange}
                    />
                    <Typography variant="subtitle2" color="secondary" sx={{ lineHeight: "1", mt: 1 }}>
                      BALANCE: {(accountTokenBalance * 10 ** -12 * 10 ** -9).toFixed(2)}T
                    </Typography>
                    <Typography variant="subtitle2" color="secondary" sx={{ lineHeight: "1", mt: 1 }}>
                      STAKED: {(userPosition.amountToken * 10 ** -12 * 10 ** -9).toFixed(2)}T
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Grid
                    container
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    sx={{
                      mt: { xs: 3, md: 0 },
                    }}
                  >
                    {tokenNeedsApproval ? (
                      <Grid item>
                        <Button onClick={onApproveTokenClicked} sx={{ width: { xs: "100%" } }} variant="contained" disabled={!props.account}>
                          APPROVE
                        </Button>
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <Button onClick={onAddTokenClicked} sx={{ width: { xs: "100%", lg: "auto" } }} variant="contained">
                            ADD
                          </Button>
                        </Grid>
                        <Grid item sx={{ pl: 1 }}>
                          <Button disabled={parseInt(userPosition.amountToken) === 0} onClick={onRemoveTokenClicked} sx={{ width: { xs: "100%", lg: "auto" } }} variant="contained">
                            REMOVE
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              p: 3,

              borderRadius: "5px",
              borderRadius: "5px",
              backgroundImage: "url('./farm_bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              mt: { xs: 5, md: 0 },
            }}
          >
            <Stack>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid
                  item
                  sx={{
                    pr: 3,
                    display: "grid",
                    gridTemplateColumns: "repeat(3,1fr)",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      gridColumn: "1 / 3",
                      gridRow: "1",
                      zIndex: "1",
                    }}
                  >
                    <img src="./farm_stake_icon_01.png" alt="Ethereum" />
                  </div>
                  <div style={{ gridColumn: "2 / -1", gridRow: "1" }}>
                    <img src="./eth.png" alt="Ethereum" />
                  </div>
                </Grid>
                <Grid item sx={{ mt: { xs: 3, lg: 0 } }}>
                  <Typography variant="h4">Stake LP tokens</Typography>
                  <Typography variant="subtitle1" sx={{ pt: 1 }}>
                    (Each 20 LP tokens) = 2 FREE PACKS PER 7 DAYS
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{ my: 3 }}>
                  <a style={{ "text-decoration": "none" }} href="https://app.uniswap.org/#/add/v2/ETH/0x1E2F15302B90EddE696593607b6bD444B64e8F02" target="_blank" rel="noreferrer">
                    <Button variant="contained" sx={{ width: "100%", background: "rgba(17, 17, 32, 1)" }}>
                      ADD LIQUIDITY <Launch style={{ height: "12px" }} />{" "}
                    </Button>
                  </a>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  p: 3,
                  background: "rgba(17, 17, 32, 0.7)",
                  borderRadius: "5px",
                }}
                container
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <>
                  <Grid item xs={12} lg={6} sx={{ pl: 1 }}>
                    <Stack>
                      <Typography variant="h4">STAKE LP TOKENS</Typography>
                      <TextField
                        sx={{ width: "100%" }}
                        label="Enter amount"
                        type="number"
                        variant="standard"
                        value={lpSupplyAmount}
                        onChange={onLPSupplyAmountChange}
                        inputProps={{
                          min: 20,
                          step: 20,
                          inputMode: "numeric",
                        }}
                      />
                      <Typography variant="subtitle2" color="secondary" sx={{ lineHeight: "1", mt: 1 }}>
                        BALANCE: {(accountLPBalance * 10 ** -18).toFixed(2)}
                      </Typography>
                      <Typography variant="subtitle2" color="secondary" sx={{ lineHeight: "1", mt: 1 }}>
                        STAKED: {web3.utils.fromWei(userPosition.amountLP.toString())} SHIRYO-WETH
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Grid
                      container
                      display="flex"
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      sx={{
                        mt: { xs: 3, md: 0 },
                      }}
                    >
                      {lpNeedsApproval ? (
                        <Grid item>
                          <Button onClick={onApproveLPClicked} sx={{ width: { xs: "100%" } }} variant="contained" disabled={!props.account}>
                            APPROVE
                          </Button>
                        </Grid>
                      ) : (
                        <>
                          <Grid item>
                            <Button onClick={onAddLPClicked} sx={{ width: { xs: "100%", lg: "auto" } }} variant="contained">
                              ADD
                            </Button>
                          </Grid>
                          <Grid item sx={{ pl: 1 }}>
                            <Button disabled={parseInt(userPosition.amountLP) === 0} onClick={onRemoveLPClicked} sx={{ width: { xs: "100%", lg: "auto" } }} variant="contained">
                              REMOVE
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
        {props.account && (
          <Grid
            sx={{
              p: 3,
              mx: 0,
              borderRadius: "5px",
              borderRadius: "5px",
              backgroundImage: "url('./farm_bg.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
              mt: 3,
            }}
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              sx={{
                p: 3,
                background: "rgba(17, 17, 32, 0.7)",
                borderRadius: "5px",
              }}
              item
              xs={12}
              lg={6}
            >
              <Stack>
                <Typography
                  variant="subtitle1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: { xs: "12px", sm: "14px" },
                  }}
                >
                  YOU CAN HARVEST NEXT PACK IN
                  <Tooltip title="If you have staked tokens and LP your earned packs will be collected in the same harvest. The countdown timer is an estimation, check back to this page regularly.">
                    <HelpOutline sx={{ fontSize: "14px", marginLeft: "4px" }} />
                  </Tooltip>
                </Typography>
                <MCCountdown endDate={parseInt(nextRewardTime)} />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                display="flex"
                sx={{
                  mt: { xs: 5, lg: 0 },
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Grid
                  item
                  sx={{
                    pl: { xs: 0, lg: 5 },
                    pr: { xs: 0, lg: 3 },
                    textAlign: "center",
                    mb: { xs: 3, lg: 0 },
                  }}
                >
                  <img style={{ paddingLeft: "16px", paddingRight: "16px" }} height="96px" src="/basepack.png" alt="Shiryo Card pack" />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Stack justifyContent="space-between">
                        <Grid item>
                          <Typography variant="h5">AVAILABLE TO HARVEST</Typography>
                          <Typography variant="subtitle1" sx={{ my: 2, fontSize: "1.25rem" }}>
                            {claimable} PACKS
                          </Typography>
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Button disabled={parseInt(claimable) === 0} onClick={onClaimClicked} sx={{ width: { xs: "100%" } }} variant="contained">
                        HARVEST
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Stack sx={{ width: "100%" }}>
                      <Divider />
                      <Typography variant="subtitle2">{userPosition.totalClaimed} PACKS HARVESTED IN TOTAL</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid
            sx={{ mt: { xs: 5 }, p: { xs: 3 } }}
            item
            xs={12}
            md={6}
            // display="flex"
            justifyContent="center"
          //alignItems="center"
          >
            <video controls style={{ width: "100%", borderRadius: "16px" }}>
              <source src="https://shiryo-media.s3.eu-west-1.amazonaws.com/Shiryo_Gameplay_Footage-2.mp4" type="video/mp4"></source>
            </video>
          </Grid>
          <Grid item xs={12} md={6} sx={{ p: 3 }}>
            <Stack>
              <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
                <Arrow />
                <Typography variant="h3" sx={{ pl: 2 }}>
                  How does this work?
                </Typography>
              </Grid>
              <Typography color="secondary">
                An investor will lock their tokens away in a vault for one week at a time, this will help stabilise volatile price action and encourage individuals to hold. Investors can withdraw their tokens at any time, but they will not receive
                their bonus unless held for the minimum time of one week.<p />
                <span style={{ color: "orange" }}>Note:</span> If more tokens are added, any outstanding packs are harvested automatically and the timer is reset to 1 week.
              </Typography>
            </Stack>
            <Stack>
              <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
                <Arrow />
                <Typography variant="h3" sx={{ pl: 2 }}>
                  Why should I stake?
                </Typography>
              </Grid>
              <Typography color="secondary">
                By staking your tokens you will be able to claim:
                <br />
                One free card pack per week per 20T tokens staked
                <br />
                And/or
                <br />
                Two free card packs per week per 20LP tokens staked.
                <br />
                <br />
                We aim to reward the long term holders by rewarding packs of cards ready for phase 2, during phase 2 users can open their cards and receive 5 random cards of a potential 150.
                <br />
                <br />
                <span style={{ color: "orange" }}>Note:</span> There is a transfer tax 11% for the SHIRYO token to support reflections, this also applies to staking and removing.
              </Typography>
            </Stack>{" "}
            <Stack>
              <Grid container alignItems="center" sx={{ pt: 5, pb: 3 }}>
                <Arrow />
                <Typography variant="h3" sx={{ pl: 2 }}>
                  How do I claim my earned packs?
                </Typography>
              </Grid>
              <Typography color="secondary">Claiming your rewards is simple, all you need to do is visit our DAPP open the farm page and click the harvest button.</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
