import { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { cardsContract, founderPackContract, normalPackContract } from "../contracts/ContractProvider";
import { useParams, useNavigate, NavLink } from "react-router-dom";
import { rarities } from "../rarities";
import MarketFPack from "./MarketFPack";
import MarketNPack from "./MarketNPack";
import GridItemCard from "./GridItemCard";

export default function ViewItem(props) {
  const [item, setItem] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [alsoInCollection, setAlsoInCollection] = useState([]);

  let { type } = useParams();

  const fetchPacks = async () => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    let packs;

    if (type === "base") {
      packs = await normalPackContract.methods
        .balanceOf(props.account)
        .call();
      packs && setItem({ "category": "Packs", "type": "base", "description": "Base pack", "quantity": packs })
      setIsLoaded(true);
    } else if (type === "founders") {
      packs = await founderPackContract.methods
        .balanceOf(props.account)
        .call();

      packs && setItem({ "category": "Packs", "type": "founders", "description": "Founders pack", "quantity": packs })
      setIsLoaded(true);
    }

    let cardBalances = await cardsContract.methods
      .balancesOf(props.account)
      .call();

    let maxCount = 0;

    cardBalances &&
      cardBalances.map((cb, i) => {
        if (parseInt(cb) > 0) {
          maxCount++;

          if (maxCount <= 6) {
            fetch(`https://api.cards.shiryo.com/card/${i + 1}.json`)
              .then((res) => res.json())
              .then((result) => {
                if (result.error !== 1) {
                  result.quantity = cb;

                  setAlsoInCollection((alsoInCollection) => [
                    ...alsoInCollection,
                    result,
                  ]);
                }
              });

          }
        }
      });

  };
  useEffect(() => {
    fetchPacks();

    return () => {
      setItem(null);
    };
  }, [type]);

  return (
    isLoaded &&
    item && (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              GO BACK
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="containedRounded"
              component={NavLink}
              to="/mint"
            >
              BUY MORE
            </Button>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <img
              src={`../${item.type}pack.png`}
              alt={item.name}
              style={{
                width: "75%",
                padding: "16px",
              }}
            />
            <Stack textAlign="center" sx={{ mt: 2 }}>
              <Typography sx={{ color: "#46E168", fontWeight: "bold" }}>
                IN YOUR COLLECTION
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.quantity} Currently Owned
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                borderRadius: "10px",
                background: "rgba(23, 24, 40, 1)",
              }}
            >
              <Stack>
                <Stack
                  sx={{
                    py: 3,
                    px: 7,
                    background:
                      "url('../top.png') no-repeat center center / cover",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      SHIRYO
                    </Typography>

                    <Typography
                      sx={{
                        color: item.type === "base" ? rarities["common"] : rarities["legendary"],
                        fontWeight: "bold",
                      }}
                    >
                      {item.type.toUpperCase()}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      QTY: {item.quantity}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#93A3AF" }}>
                      PACK
                    </Typography>
                  </Stack>
                </Stack>
                <Stack sx={{ px: 7, py: 3 }}>
                  {item.type === "founders" ?
                    <MarketFPack item={item} account={props.account} />
                    : <MarketNPack item={item} account={props.account} />}
                  <Typography variant="playSubtitle">ABOUT</Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ py: 1, wordBreak: "break-word" }}
                  >
                    {item.description}
                  </Typography>
                  {/* <Divider sx={{ my: 3 }} />
                  <Typography variant="playSubtitle">PROPERTIES</Typography>
                  <Grid
                    spacing={1}
                    container
                    display="grid"
                    sx={{ py: 1 }}
                    gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
                  >
                    {item.attributes.map((attr) => (
                      <Grid item sx={{ width: "100% !important" }}>
                        <Paper
                          variant="outlined"
                          sx={{ background: "none", p: 2, height: "100%" }}
                        >
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {attr.value === "blank" ? "No" : attr.value}{" "}
                            {attr.trait_type}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            0.1% of avatars have this trait
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid> */}
                </Stack>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
        <Divider sx={{ my: 3 }} />
        <Grid
          container
          spacing={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">ALSO IN YOUR COLLECTION</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlinedRounded"
              component={NavLink}
              to="/my-collection"
            >
              VIEW ALL
            </Button>
          </Grid>
        </Grid>
        {alsoInCollection &&
          <>
            <Divider sx={{ my: 3 }} />
            <Grid container
              spacing={2}
              sx={{ pt: 3, pb: 3 }}
              display="grid"
              gridTemplateColumns="repeat(auto-fill, minmax(170px, 1fr))">
              {alsoInCollection.map(
                (alsoInCollection, index) =>
                  index < 6 && (
                    <Grid key={index} item sx={{ margin: "0 auto" }}>
                      <GridItemCard item={alsoInCollection} index={index} />
                    </Grid>
                  )
              )}
            </Grid>
          </>
        }
      </Container>
    )
  );
}
