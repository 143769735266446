import { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
  Select,
  Stack,
  MenuItem,
} from "@mui/material";

export default function Filter({
  title,
  setQuery,
  filterParam,
  setFilterParam,
  sortBy,
  setSortBy,
  sortOptions,
}) {
  return (
    <>
      <Grid
        container
        display="grid"
        alignItems="center"
        sx={{
          my: 3,
          pt: 3,
          gridTemplateColumns: { md: "auto 1fr", xs: "unset" },
          gridTemplateRows: { xs: "1fr 1fr", md: "unset" },
        }}
      >
        <Grid item>
          <Typography variant="h1">{title}</Typography>
        </Grid>

        <Grid item sx={{ mr: { md: 3, xs: 0 } }}>
          <TextField
            sx={{
              width: "100%",
              ml: { md: 3, xs: 0 },
              mt: { md: 0, xs: 3 },

              border: "1px solid rgba(56,59,77,0.3)",
            }}
            label="Search"
            size="small"
            variant="outlined"
            defaultValue=""
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        display="grid"
        alignItems="center"
        sx={{
          my: 3,
          pt: 3,
          gridTemplateColumns: { md: "1fr 1fr", xs: "unset" },
          gridTemplateRows: { xs: "1fr 1fr", md: "unset" },
          justifyContent: "space-between",
        }}
      >
        <Grid item>
          <Grid container gap={3} sx={{ mb: { sm: 3, md: 0 } }}>
            <Button
              variant={
                filterParam === "all" ? "containedRounded" : "outlinedRounded"
              }
              onClick={() => setFilterParam("all")}
            >
              VIEW ALL
            </Button>
            <Button
              variant={
                filterParam === "Cards" ? "containedRounded" : "outlinedRounded"
              }
              onClick={() => setFilterParam("Cards")}
            >
              CARDS
            </Button>

            <Button
              variant={
                filterParam === "pack" ? "containedRounded" : "outlinedRounded"
              }
              onClick={() => setFilterParam("Packs")}
            >
              PACKS
            </Button>

            <Button
              variant={
                filterParam === "Avatars"
                  ? "containedRounded"
                  : "outlinedRounded"
              }
              onClick={() => setFilterParam("Avatars")}
            >
              AVATARS
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ textAlign: { md: "right", xs: "left" } }}>
          <Select
            sx={{ border: "1px solid rgba(56,59,77,0.3) !important" }}
            size="small"
            defaultValue={"-"}
            onChange={(e) => setSortBy(e.target.value)}
            renderValue={(value) => (
              <Stack
                sx={{ with: "100%" }}
                direction="row"
                justifyContent="space-between"
              >
                <Typography sx={{ mr: 1 }} color="secondary">
                  Sort By:{" "}
                </Typography>
                <Typography>{value}</Typography>
              </Stack>
            )}
          >
            {sortOptions &&
              sortOptions.map((so) => (
                <MenuItem key={so} value={so}>
                  {so}
                </MenuItem>
              ))}
          </Select>
          {/* <Button variant="outlinedRounded">Sort by: Latest</Button> */}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
