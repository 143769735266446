import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#2b43c2",
    },
    secondary: {
      main: "#93A3AF",
    },
    default: {
      main: "#E5F3F8",
    },
    background: {
      default: "#111120",
      paper: "#04040e",
    },
    divider: "rgba(56,59,77,0.3)",
    text: {
      primary: "#fff",
      secondary: "#93A3AF",
      disabled: "rgba(255, 255, 255, 0.5)",
      hint: "rgba(255, 255, 255, 0.5)",
    },
  },
  typography: {
    fontFamily: "Play",
    h1: {
      fontFamily: "Bungee",
      fontSize: "30px",
      letterSpacing: "0.09rem",
    },
    h2: {
      fontFamily: "Bungee",
      fontSize: "24px",
      letterSpacing: "0.09rem",
    },
    h3: {
      fontFamily: "Bungee",
      fontSize: "18px",
      letterSpacing: "0.09rem",
    },
    h4: {
      fontFamily: "Bungee",
      fontSize: "14px",
      letterSpacing: "0.09rem",
    },
    h5: {
      fontFamily: "Bungee",
      fontSize: "12px",
      letterSpacing: "0.09rem",
    },
    h6: {
      fontFamily: "Bungee",
      fontSize: "10px",
      letterSpacing: "0.09rem",
    },
    subtitle1: {
      fontFamily: "Play",
      lineHeight: "88.8%",
      fontSize: "14px",
      letterSpacing: "0.09rem",
      color: "#93A3AF",
    },
    subtitle2: {
      fontFamily: "Play",
      lineHeight: "242%",
      fontSize: "12px",
      letterSpacing: "0.09rem",
      color: "#fff",
    },
    playSubtitle: {
      fontFamily: "Play",
      fontSize: "12px",
      letterSpacing: "0.09rem",
      textTransform: "uppercase",
      lineHeight: "19px",
      fontWeight: "500",
      color: "#fff",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { color: "primary", variant: "containedRounded" },
          style: {
            height: "32px",
            padding: "10px 20px",
            borderRadius: "5px !important",
            backgroundColor: "#2b43c2",
          },
        },
        {
          props: { color: "primary", variant: "outlinedRounded" },
          style: {
            height: "32px",
            backgroundColor: "#0F152A",
            borderRadius: "5px !important",
            color: "rgba(147, 163, 175, 1)",
            padding: "10px 20px",
          },
        },
        {
          props: { color: "primary", variant: "outlinedDarkRounded" },
          style: {
            height: "32px",
            background: "transparent",
            borderRadius: "5px !important",
            color: "rgba(147, 163, 175, 1)",
            border: "1px solid rgba(56,59,77,0.3)",
            padding: "10px 20px",
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: { color: "primary", variant: "outlined" },
          style: {
            background: "yellow",
            border: "5px solid yellow",
            padding: "0px !important",
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: "16px",
          background: "#141729",
          borderRadius: "10px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          color: "#93A3AF",
        },
      },
    },
  },
});

export default theme;
