import {
  Stack,
  Typography,
  Grid,
  Divider,
  Button,
  Container,
} from "@mui/material";

import { useParams, NavLink } from "react-router-dom";
import CardGrid from "../Components/CardGrid";
import { exampleItems } from "../exampleItems";
import { useEffect, useState } from "react";
import Filter from "../Components/Filter";

import {
  marketContractAddress,
  cardsContractAddress,
  marketContract,
  founderPackAddress,
} from "../contracts/ContractProvider";

const SORTOPTIONS = ["Price: Low to High", "Price: High to Low"];

export default function Marketplace() {
  const [query, setQuery] = useState("");
  const [filterParam, setFilterParam] = useState("");

  const [searchParam] = useState(["card_name", "description"]);

  const [isLoaded, setIsLoaded] = useState(true);
  const [items, setItems] = useState([]);

  const [error, setError] = useState(null);

  function search(items) {
    return items.filter((item) => {
      if (item.category == filterParam) {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      } else if (filterParam == "all") {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      } else if (filterParam == "") {
        return searchParam.some((newItem) => {
          if (item[newItem] !== undefined)
            return (
              item[newItem]
                .toString()
                .toLowerCase()
                .indexOf(query.toLowerCase()) > -1
            );
        });
      }
    });
  }

  const handleSortBy = (sortBy) => {
    switch (sortBy) {
      case "Price: Low to High":
        const lowToHigh = [...items].sort((a, b) => {
          return a.card.price - b.card.price;
        });
        setItems(lowToHigh);

        break;
      case "Price: High to Low":
        const highToLow = [...items].sort((a, b) => {
          return b.card.price - a.card.price;
        });
        setItems(highToLow);

        break;
      default:
        break;
    }
  };

  const fetchItems = async () => {
    const marketItems = await marketContract.methods.getMarketItems().call();
    console.log(marketItems);
    setItems([]);
    marketItems.map((marketItem) => {
      if (marketItem.category === "Avatars") {
        fetch(`https://api.avatars.shiryoinu.com/${marketItem.tokenId}`)
          .then((res) => res.json())
          .then((result) => {
            if (result.error !== 1) {
              result.category = marketItem.category;
              result.card = marketItem;

              setItems((items) => [...items, result]);
            }
          });
      } else if (marketItem.category === "Cards") {
        fetch(`https://api.cards.shiryo.com/${marketItem.tokenId}.json`)
          .then((res) => res.json())
          .then((result) => {
            if (result.error !== 1) {
              result.category = marketItem.category;
              result.card = marketItem;

              setItems((items) => [...items, result]);
            }
          });
      } else {
        let result = {};
        result.category = marketItem.category;
        result.card = marketItem;
        result.description = "Pack";
        result.type =
          marketItem.tokenContract === founderPackAddress ? "founders" : "base";
        setItems((items) => [...items, result]);
      }
    });
  };

  useEffect(() => {
    try {
      fetchItems();
    } catch (e) {
      setError(e);
    }
    return () => setItems([]);
  }, []);

  items.length > 0 && !isLoaded && setIsLoaded(true);
  return (
    <Container sx={{ my: 5 }}>
      {filterParam !== "" ? (
        <Filter
          title="THE MARKETPLACE"
          setQuery={setQuery}
          filterParam={filterParam}
          setFilterParam={setFilterParam}
          setSortBy={handleSortBy}
          sortOptions={SORTOPTIONS}
        />
      ) : (
        <>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sx={{ py: 3 }}>
              <Typography variant="h1">THE MARKETPLACE</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="containedRounded"
                component={NavLink}
                to="/my-collection"
              >
                SELL
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={8}>
              <img
                onClick={() => setFilterParam("Cards")}
                src="./Marketplace_CTA_Big_01.png"
                alt="Top up your collection"
                style={{ width: "100%", cursor: "pointer" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ pl: { sm: 3, xs: 0 } }}>
              <Stack spacing={3}>
                <img
                  onClick={() => setFilterParam("pack")}
                  src="./Marketplace_CTA_Packs_01.png"
                  alt="Top up your collection"
                  style={{ width: "100%", cursor: "pointer" }}
                />
                <img
                  onClick={() => setFilterParam("Avatars")}
                  src="./Marketplace_CTA_Avatars_01.png"
                  alt="Top up your collection"
                  style={{ width: "100%", cursor: "pointer" }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid
            sx={{ mt: 3 }}
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h5">RECENTLY LISTED</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlinedRounded"
                onClick={() => setFilterParam("all")}
              >
                VIEW ALL
              </Button>
            </Grid>
          </Grid>
          <Divider sx={{ my: 3 }} />
        </>
      )}

      <CardGrid
        items={items}
        error={error}
        isLoaded={isLoaded}
        search={search}
        max={24}
        market={true}
      />
    </Container>
  );
}
