import { Button, Grid, Paper, Typography } from "@mui/material";

export default function ConnectWallet({ subtitle, ...props }) {
  return (
    <Grid
      item
      sm={12}
      md={5}
      sx={{
        pt: 5,
        margin: "0 auto",
        textAlign: "center",
        position: "relative",
        overflow: "hidden",
        borderRadius: "10px",
      }}
    >
      <img
        src="../wolf.png"
        alt="Shiryo"
        style={{
          transform: "scale(-1,1)",
          height: "90%",
          position: "absolute",
          bottom: -24,
          left: -16,
          zIndex: -1,
        }}
      />
      <Paper
        variant="outlined"
        sx={{
          py: 7,
          borderRadius: "10px",
          background: "rgba(20, 22, 40, 0.8)",
        }}
      >
        <Typography variant="h1">Connect wallet</Typography>
        <Typography variant="subtitle1" sx={{ textTransform: "uppercase" }}>
          {subtitle}
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => props.setWalletModal(true)}
        >
          CONNECT
        </Button>
      </Paper>
    </Grid>
  );
}
