import { Grid, Button, Paper, Typography, Box, Stack, FormControl, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { Close } from "@mui/icons-material";

import {
  marketContractAddress,
  founderPackContract,
  founderPackAddress,
  web3,
} from "../contracts/ContractProvider";
import ConnectedWeb3Provider from "../wallet/ConnectedWeb3Provider";
import { marketContractABI, AvatarsABI, IERC20 } from "../contracts/contractABI";
import { useTransactionMonitor } from "../hooks/useTransactionMonitor";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  select: {
    "&&": { padding: "0px 0px 0px 16px", height: "34px", background: "#2b43c2", borderRadius: "0px", display: "flex", justifyContent: "center", alignItems: "center" },
  },
});

export default function MarketFPack(props) {
  const [listPrice, setListPrice] = useState(0);
  const [listed, setListed] = useState(Boolean(listPrice));
  const [packAmount, setPackAmount] = useState(1);
  const [approvedForAll, setApprovedForAll] = useState(false);
  const navigate = useNavigate();
  const { monitorTransaction } = useTransactionMonitor();
  const web3I = ConnectedWeb3Provider.web3; // use the connected wallet (this is my personal trick, there is probably a better way)
  const classes = useStyles();
  const onListItemClicked = async (tokenId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;


    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    ); // create a writeable version of the contract

    try {
      // get the cost from the contract so that it's always up to date.
      const ERC20 = 2;
      let listPriceWei = web3.utils.toWei(listPrice.toString(), "ether");

      //console.log("Listing at: " + listPriceWei);
      //console.log(tokenId);
      const estimatedGas = await MarketWrite.methods
        .createMarketItem(
          founderPackAddress,
          ERC20,
          0,
          packAmount,
          listPriceWei,
          "Packs"
        )
        .estimateGas({ from: props.account });
      console.log(estimatedGas);


      const listOnMarket = await MarketWrite.methods
        .createMarketItem(
          founderPackAddress,
          ERC20,
          0,
          packAmount,
          listPriceWei,
          "Packs"
        )
        .send(
          { from: props.account, gas: estimatedGas + 50000 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => {
                console.log(txHash);
              });
            }
          }
        )
        .catch(() => { });

      listOnMarket.status && navigate("/market/" + listOnMarket.events.MarketItemCreated.returnValues.itemId, { replace: true })

    } catch (error) {
      console.log(error);
    }
  };

  const unListItem = async (itemId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );

    try {
      await MarketWrite.methods
        .cancelMarketItem(itemId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  const checkApproved = async () => {
    const allowanceApproved = await founderPackContract.methods
      .allowance(props.account, marketContractAddress)
      .call();

    if (allowanceApproved && parseInt(allowanceApproved)>0) {
      setApprovedForAll(true);
    }
  };

  const onApproveClicked = async () => {
    try {
      const TokenContractWrite = new web3I.eth.Contract(IERC20, founderPackAddress);
      const amount =
        "115792089237316195423570985008687907853269984665640564039457584007913129639935";
      //const amountWei = web3.utils.toWei(amount, "ether");

      const estimatedGas = await TokenContractWrite.methods
        .approve(marketContractAddress, amount)
        .estimateGas({ from: props.account });

      const result = TokenContractWrite.methods
        .approve(marketContractAddress, amount)
        .send(
          { from: props.account, gas: estimatedGas + 500 },
          (error, txHash) => {
            if (!error) {
              monitorTransaction(error, txHash, async () => { 

                checkApproved();

              });
            }
          }
        )
        .catch(() => { });
      console.log(result)
    } catch (error) {
      console.log(error);
    }
  };

  const acceptItemOffer = async (offerId) => {
    if (
      props.account === undefined ||
      props.account === null ||
      props.account === false
    )
      return;

    const MarketWrite = new web3I.eth.Contract(
      marketContractABI,
      marketContractAddress
    );
    ////console.log(offerId);
    try {
      await MarketWrite.methods
        .acceptItemOffer(offerId)
        .send({ from: props.account }, (error, txHash) => {
          if (!error) {
            monitorTransaction(error, txHash, async () => {
              //console.log(txHash);
            });
          }
        })
        .catch(() => { });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkApproved();
  }, []);
  //console.log(props);
  return (
    <Grid container spacing={5} sx={{ py: 3 }}>
      <Grid item md={props.item.hasHighestOffer && 6} xs={12}>
        <Paper sx={{ p: 3, background: "rgba(17, 17, 32, 0.7)" }}>
          <Typography variant="h6">
            {props.owner ? "LISTED" : "LIST"} ON MARKETPLACE
          </Typography>

          {!approvedForAll ? (
            <Grid container width="100%" sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <Button
                  sx={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  onClick={() => onApproveClicked()}
                >
                  APPROVE LISTING
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              {props.owner ? (
                <Grid container width="100%" sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      color="primary"
                      onClick={() => unListItem(props.item.card.itemId)}
                    >
                      UNLIST
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        position: "relative",
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {web3.utils.fromWei(props.item.card.price)} MATIC
                      </Typography>
                      <Close
                        sx={{
                          position: "absolute",
                          right: "8px",
                          color: "#2E3A59",
                        }}
                        onClick={() => unListItem(props.item.card.itemId)}
                        fontSize="12px"
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : (
                <Grid container width="100%" sx={{ pt: 1, flexDirection: { xs: "column", md: "row" } }}>
                  <Grid sx={{ maxWidth: { xs: "100%", sm: "unset" } }} item xs={props.item.quantity > 1 ? 4 : 5}>
                    <>
                      {props.item.quantity > 1 ? (
                        <FormControl fullWidth>
                          <Select

                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },

                            }}
                            sx={{
                              textAlign: "center",
                              padding: "0px !important",
                              borderRadius: "0px !important"
                            }}
                            classes={{ select: classes.select }}
                            value={packAmount}
                            onChange={(e) => {
                              setPackAmount(e.target.value);
                            }}

                          >


                            {[...Array(parseInt(props.item.quantity))].map((v, i) => {
                              return (i >= 0 &&
                                <MenuItem key={i+1} value={i+1}>{i+1} {i+1 > 1 ? "PACKS" : "PACK"}</MenuItem>
                              )
                            })}


                          </Select>
                        </FormControl>) :
                        <Button
                          sx={{ width: "100%" }}
                          variant="contained"
                          color="primary"
                          onClick={() => onListItemClicked(props.item.edition)}
                        >
                          LIST
                        </Button>
                      }
                    </>
                  </Grid>
                  <Grid sx={{ maxWidth: { xs: "100%", sm: "unset" } }} item xs={props.item.quantity > 1 ? 6 : 7}>
                    <Box
                      sx={{
                        background: "black",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #2B43C2",
                      }}
                    >
                      <NumberFormat
                        style={{ padding: "8px" }}
                        onValueChange={(values) => {
                          setListPrice(values.floatValue);
                        }}
                        className="numberFormat"
                        suffix=" MATIC"
                        placeholder="ENTER PRICE"
                      />
                    </Box>
                  </Grid>
                  {props.item.quantity > 1 &&
                    <Grid sx={{ maxWidth: { xs: "100%", sm: "unset" } }} item xs={2}>
                      <Button
                        sx={{ width: "100%" }}
                        variant="contained"
                        color="primary"
                        onClick={() => onListItemClicked(props.item.edition)}
                      >
                        LIST
                      </Button>
                    </Grid>
                  }
                </Grid>
              )}
            </>
          )}
        </Paper>
      </Grid>
      {props.item.hasHighestOffer && (
        <Grid item md={6} xs={12}>
          <Paper sx={{ p: 3, background: "rgba(17, 17, 32, 0.7)" }}>

            <>
              <Stack direction="row">
                <Typography variant="h6">TOP OFFER</Typography>
                <Typography
                  sx={{ ml: 1 }}
                  variant="body2"
                  color="secondary"
                  fontSize="11px"
                >
                  {props.item && props.item.highestOffer.bidder.substr(0, 6)}...
                  {props.item &&
                    props.item.highestOffer.bidder.substr(
                      props.item.highestOffer.bidder &&
                      props.item.highestOffer.bidder.length - 3,
                      3
                    )}
                </Typography>
              </Stack>
              <Grid container width="100%" sx={{ pt: 1 }}>
                <Grid item xs={4}>
                  <Button
                    sx={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      acceptItemOffer(props.item.highestOffer.offerId)
                    }
                  >
                    ACCEPT
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{
                      background: "black",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #2B43C2",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {parseFloat(
                        web3.utils.fromWei(props.item.highestOffer.offerAmount)
                      ).toFixed(2)}{" "}
                      MATIC
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>

          </Paper>
        </Grid>
      )}
    </Grid>
  );
}
